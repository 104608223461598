import '../assets/css/sidebar.css'
import Logo from "../assets/images/logo.svg"
import { Button } from 'react-bootstrap';
import { BiFolder, BiCalendar, BiUser} from "react-icons/bi";
import { getAuth, signOut} from "firebase/auth";



function Sidebar() {
  const auth = getAuth();
  

  function SignOut(){
   
      signOut(auth).then(() => {
          window.location.href = './';
      }).catch((error) => {
  
      });
  }
 



  return (
    
    <>
       <nav class="sidebar">
      <ul class="sidebar-nav">
        <li class="sidebar-item">
          <a href="./" class="sidebar-link">
          <img src={Logo} height={35}/>
          </a>
        </li>

        
  
        <li class="sidebar-item">
          <a href="./calendar" class="sidebar-link">
            <BiCalendar style={{fontSize: 30}}/>
   
          </a>
        </li>

        
  
        <li class="sidebar-item">
          <a href="./profile" class="sidebar-link">
            <BiUser style={{fontSize: 30}}/>
           
          </a>
        </li>
  
        <li class="sidebar-item">
          <a href="#" class="sidebar-link" onClick={SignOut}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="40px" height="40px"><path d="M 16 3 C 8.832031 3 3 8.832031 3 16 C 3 23.167969 8.832031 29 16 29 C 23.167969 29 29 23.167969 29 16 C 29 12.417969 27.539063 9.164063 25.1875 6.8125 L 27 5 L 21 4 L 22 10 L 23.78125 8.21875 C 25.769531 10.210938 27 12.957031 27 16 C 27 22.085938 22.085938 27 16 27 C 9.914063 27 5 22.085938 5 16 C 5 9.914063 9.914063 5 16 5 Z M 16 8 C 13.25 8 11 10.25 11 13 C 11 14.515625 11.707031 15.863281 12.78125 16.78125 C 10.53125 17.949219 9 20.300781 9 23 L 11 23 C 11 20.226563 13.226563 18 16 18 C 18.773438 18 21 20.226563 21 23 L 23 23 C 23 20.300781 21.46875 17.949219 19.21875 16.78125 C 20.292969 15.863281 21 14.515625 21 13 C 21 10.25 18.75 8 16 8 Z M 16 10 C 17.667969 10 19 11.332031 19 13 C 19 14.667969 17.667969 16 16 16 C 14.332031 16 13 14.667969 13 13 C 13 11.332031 14.332031 10 16 10 Z"/></svg>
    
           
          </a>
        </li>
        
        
       
      </ul>
    </nav>
    </>
  
       
     
    );

 
}

export default Sidebar;
