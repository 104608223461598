
import { Col, Row, Button, Form, Badge} from 'react-bootstrap';
import {useEffect, useState} from 'react'
import getTodoList from '../core/TodoList';
import getUserData from '../core/UserData';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import getShareList from '../core/ShareList';
import getTodosShareList from '../core/TodosShareList';
import {  deleteDoc, doc, addDoc, collection} from "firebase/firestore";
import initFirebase from '../firebase/Init';
import { If, Then, Else } from 'react-if';
import { PiSmileyWinkBold } from "react-icons/pi";
import { IoClose } from 'react-icons/io5';
import { CheckboxToggle } from 'react-rainbow-components';
import { ToastContainer, toast } from 'react-toastify';
import { ColorRing } from 'react-loader-spinner';
import { Card, Modal } from 'react-rainbow-components';

function Todos() {
  const [friendsData, setFriendsData] = useState([])
const [todosData, setTodosData] = useState([])
const [todosSharedData, setTodosSharedData] = useState([])

const [pageLoading, setPageLoading] = useState(false)

const [showDelete, setShowDelete] = useState(false);


const [newTodoText,  setNewTodoText] = useState('')
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = (id, uid, mobile, sms) => {
    setIdDelete(id);
    setUidDelete(uid);
    setSmsDelete(sms);
    setMobileDelete(mobile);
    setShowDelete(true);
  } 

  
  const [userName, setUserName] = useState('');
  const [idDelete, setIdDelete] = useState('')
  const [uidDelete, setUidDelete] = useState('')
  const [smsDelete, setSmsDelete] = useState(false)
  const [mobileDelete, setMobileDelete] = useState('')
  const [show, setShow] = useState(false);
  const [share, setShare] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setNewTodoText('')
    
    setShow(true)};

initFirebase();
  const db = getFirestore();
  const auth = getAuth();


useEffect(() => {
  window.addEventListener("focus", e => window.location.reload());
  const getUserInfo = async () =>{
    getUserData(auth.currentUser.uid).then((result) => {
        setUserName(result.docs[0].data().username)
       
    });
}

    const getTodos = async () => {
        getTodoList(auth.currentUser.uid).then((result) => {
           pushTodosData(result)
         })
    }

    const getShare = async () =>{
    
        getShareList(auth.currentUser.uid).then((result) => {
         
          if(result.size > 0)
          {

            pushFriendsData(result)
            result.forEach((doc) => {
          
              getTodosShareList(doc.data().uid).then((results) => {
         
                pushSharedTodosData(results)
               
               });
            })
          }
         
         
         });
      }
 Promise.all([getTodos(), getShare(), getUserInfo()]).then(setPageLoading(true))
},[])

function clearTodosData (){
    return new Promise((resolve) => {
      setTodosData([]);
      setTodosSharedData([]);
      resolve(true);
    });

  }

const pushTodosData =  (result) =>{

    var array = [];
    var i = 0;
    result.forEach((doc) => {
  
      
        array.push({docid: doc.id, uid: doc.data().uid, text: doc.data().text});
        i++;
        if(i == result.size){
        setTodosData(array);
      
        }
      
    });

}

const pushFriendsData =  (result) =>{

  var array = [];
  var i = 0;
  result.forEach((doc) => {

    getUserData(doc.data().uid).then((res) => {
     
    if(res.docs[0].data().sms === true){
      array.push({uid: doc.data().uid,  username: res.docs[0].data().username, mobile: res.docs[0].data().mobile, sms: res.docs[0].data().sms});
    }
      i++;
      if(i == result.size){
      setFriendsData(array);
      console.log("friends")
      console.log(array)
     
      }
     });
     
    
  });

}

const pushSharedTodosData =  (result) =>{

  var array = [];
  var i = 0;
  result.forEach((doc) => {

    getUserData(doc.data().uid).then((res) => {
     
   
      array.push({docid: doc.id, uid: doc.data().uid,  text: doc.data().text, username: res.docs[0].data().username, mobile: res.docs[0].data().mobile, sms: res.docs[0].data().sms});
      i++;
      if(i == result.size){
      setTodosSharedData(array);

     
      }
     });
     
    
  });

}

const handleDone = (id, uid, mobile, sms) => {
    setPageLoading(false)
    if(mobile !== "" && sms === true){
      deleteDoc(doc(db, "todos", id)).then(() => {
     
        clearTodosData().then(() => {
          getTodoList(auth.currentUser.uid).then((result) => {
       
            pushTodosData(result)
           
           });
           getShareList(auth.currentUser.uid).then((result) => {
           
              if(result.size > 0)
              {
                result.forEach((doc) => {
           
                  getTodosShareList(doc.data().uid).then((results) => {
             
                    pushSharedTodosData(results)
                   
                   });
                })
              }
             
             
             });
           handleCloseDelete()
           setPageLoading(true)
  
      }) 
    });
      fetch("https://data.filesbug.net/message.php?number="+mobile+"&message="+userName+" hat eine Aufgabe erledigt!", {
        method: 'GET',
        credentials: 'include', 
    })
        .then(response => response.text())
        .then(data => {})
        .catch(error => {
            console.error('Error:', error);
          
        });
    }else{
      deleteDoc(doc(db, "todos", id)).then(() => {
     
        clearTodosData().then(() => {
          getTodoList(auth.currentUser.uid).then((result) => {
       
            pushTodosData(result)
           
           });
           getShareList(auth.currentUser.uid).then((result) => {
           
              if(result.size > 0)
              {
                result.forEach((doc) => {
           
                  getTodosShareList(doc.data().uid).then((results) => {
             
                    pushSharedTodosData(results)
                   
                   });
                })
              }
             
             
             });
           handleCloseDelete()
           setPageLoading(true)
  
      }) 
    });
    }
   
}

const handleCreate = () => {
   
        if(newTodoText == ''){
            toast.error("Bitte gib einen Text an!")
          }else{
      addDoc(collection(db, "todos"), {
        uid: auth.currentUser.uid,
        shared: share,
        
        text: newTodoText.replace(/\n/g, '<br/>')
      }).then(() => {
        if(share === true){


          friendsData.forEach((doc) => {
            fetch("https://data.filesbug.net/message.php?number="+doc['mobile']+"&message="+userName+" hat eine Aufgabe erstellt!", {
              method: 'GET',
              credentials: 'include', 
          })
              .then(response => response.text())
              .then(data => {})
              .catch(error => {
                  console.error('Error:', error);
                
              });
          
             
            
          });
        }
        setPageLoading(false)
        handleClose()
          clearTodosData().then(() => {
            getTodoList(auth.currentUser.uid).then((result) => {
         
              pushTodosData(result)
             
             });
             getShareList(auth.currentUser.uid).then((result) => {
         
                if(result.size > 0)
                {
                  result.forEach((doc) => {
             
                    getTodosShareList(doc.data().uid).then((results) => {
               
                      pushSharedTodosData(results)
                     
                     });
                  })
                }
            });

             setPageLoading(true)
        }) 
    
      });

    
}
}

    




  
if(!pageLoading){
return (<div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}}> <ColorRing
    visible={true}
    height="200"
    width="200"
    ariaLabel="blocks-loading"
    wrapperStyle={{}}
    wrapperClass="blocks-wrapper"
    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
  /></div>)
}else{
    return (
    
        <>
        
         <center><Button onClick={handleShow} className='btn-turquoise' style={{marginBottom: 40, width: "100%"}}>Aufgabe Erstellen</Button></center>
      <If condition={todosData.length < 1 && todosSharedData.length < 1}>
        <Then><center><br/><h4 style={{color: "lightgray"}}>Keine Aufgaben</h4><p style={{fontSize: "4rem", color: "lightgray"}}><PiSmileyWinkBold/></p></center></Then>
        <Else>
        <Row xs={1} md={1} lg={1}>
          {todosData.map((item, i) => {
            return(
            <Col key={i} style={{marginBottom: 20}}>
              <div onClick={() => {handleShowDelete(item.docid, item.uid,  "", false)}}>
                <Card style={{textAlign: "center", padding: 20,  paddingBottom: 10,cursor: "pointer"}}>
                
                      
                        
                        <p dangerouslySetInnerHTML={{ __html: item.text }} />
                    
                        
              
                </Card>
                </div>
            </Col>
)
          })}

{todosSharedData.map((item, i) => {
            return(
            <Col key={i} style={{marginBottom: 20}}>
              <div onClick={() => {handleShowDelete(item.docid, item.uid, item.mobile, item.sms)}}>
               <Card style={{textAlign: "center", padding: 20, paddingBottom: 10, cursor: "pointer"}}>
             
                        
                       
                        <p dangerouslySetInnerHTML={{ __html: item.text }} />
                        <div style={{width: "100%", padding: 10, display: "flex", justifyContent: "center"}}> <Badge style={{ width: "100%", color: "black", border: "solid 1px lightgray", padding: 10, fontWeight: "normal", fontSize: "0.8rem"}} bg="light">{item.username}</Badge></div>
                    
                        
                       
                        
                  
                </Card>
                </div>
            </Col>
)
          })}


            
          </Row>

        </Else>
      </If>
          
      
        

      <Modal id="modal-1" isOpen={show} onRequestClose={handleClose} style={{padding: 30}}>
      <center><h4>Erstelle eine neue Aufgabe</h4></center><br/>
      <Form>
      
      <Form.Group className="mb-3">
       
        <Form.Control as="textarea" rows={6} onChange={ e => setNewTodoText(e.target.value) } />
      </Form.Group>
    </Form>
        <center><br/>
  <CheckboxToggle
                  id="checkbox-toggle-component-1"
                  label="Mit Freunden Teilen"
            value={share}
            onChange={() => {
              if(share == true){
                setShare(false)
              }else{
                setShare(true)
              }
            }}
            
              />
  <br/><br/>
      <Button className='btn-turquoise' size='sm' onClick={handleCreate} >
            Erstellen
          </Button>

          </center>
                </Modal>

      <Modal id="modal-1" isOpen={showDelete} onRequestClose={handleCloseDelete} style={{padding: 30}}> 
      <center><h4>Wurde die Aufgabe erledigt?</h4></center><br/>
      <center>
 
      <Button variant="danger" size="sm" onClick={handleCloseDelete} style={{marginRight: 10}}>
            Nein
          </Button>
          
          <Button variant="success" size="sm" onClick={() => {handleDone(idDelete, uidDelete, mobileDelete, smsDelete)}}>Ja</Button>
          </center>
                </Modal>
          
        
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        style={{width: 500}}
        draggable
        pauseOnHover
        theme="light"
        />
        </>
      
           
         
        );
}



 
}

export default Todos;
