import Sidebar from "../components/Sidebar";
import FilesCalendar from "../components/Calendar";
import '../assets/css/main.css';
function Calendar() {
  return (
    <><Sidebar/>
        <FilesCalendar/>
    </>
  );
}

export default Calendar;
