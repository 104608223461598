import Sidebar from "../components/Sidebar";
import FilesProfile from "../components/Profile";

function Profile() {
  return (
    <>
    <Sidebar/>
    <FilesProfile/>

    </>
  );
}

export default Profile;
