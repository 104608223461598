import Sidebar from "../components/Sidebar";
import Todos from "../components/Todos";
import { useState, useEffect } from "react";
import '../assets/css/main.css';
function Home() {

  const [height, setHeight] = useState(0);

    const updateWindowDimensions = () => {
        const newHeight = window.innerHeight;
        setHeight(newHeight);
        console.log('updating height');   
    };
    
     useEffect(() => {
         window.addEventListener('resize', updateWindowDimensions);
     
     }, []);

  return (
    <>
      <Sidebar/>
      <main style={{padding: 30, height: height-81}}>
      
<Todos/>
      </main>
    </>
  );
}

export default Home;
