
import { useState } from 'react';

import { Button, Form, Navbar, InputGroup} from 'react-bootstrap';
import { MdPassword, MdSupervisorAccount, MdEmail} from "react-icons/md";
import {  deleteDoc, doc, addDoc, collection} from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword} from "firebase/auth";
import initFirebase from '../../firebase/Init';
import { getFirestore } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import { Card, Modal } from 'react-rainbow-components';


function SignIn() {


  //FIREBASE
  initFirebase();

  const db = getFirestore();
  
  //AUTH
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  

  //MODAL
  const [showSignUp, setShowSignUp] = useState(false);

  //FORM
  const [ signInForm, setSignInForm ] = useState({});
  const [ signInErrors, setSignInErrors ] = useState({});

  const [ signUpForm, setSignUpForm ] = useState({});
  const [ signUpErrors, setSignUpErrors ] = useState({});


  //GOOGLE SIGNIN
  const googleSignIn = () => {
    signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      window.location.href = './';
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
  }

 

  //SIGNIN FORM
  const setSignInField = (field, value) => {
    setSignInForm({
        ...signInForm,
        [field]: value
      });
      if ( !!signInErrors[field] ) setSignInErrors({
          ...signInErrors,
          [field]: null
      });
};

const findSignInFormErrors = () => {
    const { email, password } = signInForm;
    const newErrors = {};
 
    if ( !email || email === ''  ){
      newErrors.email = "Gib eine E-Mail ein!";
    } else{
      if ( !password || password === ''  ) newErrors.password = "Gib ein Passwort ein!";
    }
   
    
   
  
    
    return newErrors
};

//SIGNUP FORM
const setSignUpField = (field, value) => {
  setSignUpForm({
      ...signUpForm,
      [field]: value
    });
    if ( !!signUpErrors[field] ) setSignUpErrors({
        ...signUpErrors,
        [field]: null
    });
};

const findSignUpFormErrors = () => {
  const { username, email, password, confirmpassword, samepassword } = signUpForm;
  const newErrors = {};
  
  if ( !username || username === ''  ) {
    newErrors.username = "Gib einen Benutzernamen ein!"
  }else{
    if ( !email || email === ''  ){
      newErrors.email = "Gib eine E-Mail ein!"
    }else{
      if ( !password || password === ''  ){
        newErrors.password = "Gib ein Passwort ein!"
      }else{
        if ( !confirmpassword || confirmpassword === ''  ){
          newErrors.confrimpassword = "Bestätige dein Passwort!"
        }else{
          if(password != confirmpassword){
            newErrors.samepassword = "Bitte stellen Sie sicher, dass Ihre Kennwörter übereinstimmen!"
          }else{
            if(password.length < 8){
              newErrors.samepassword = "Das Passwort muss mindestens 8 Zeichen lang sein!"
            }
          }
        }
      }
    }
  }

  

  return newErrors
};


//HANDLE SIGNUP
const handleSignUp = () => {
       
  const newErrors = findSignUpFormErrors();
  if ( Object.keys(newErrors).length > 0 ) {      
   
      Object.keys(newErrors).map((key) => {toast.error(newErrors[key])});
      
      }else{
      
        createUserWithEmailAndPassword(auth, signUpForm.email, signUpForm.password)
        .then((userCredential) => {
          addDoc(collection(db, "user"), {
            uid: userCredential.user.uid,
            username: signUpForm.username,
            email: signUpForm.email
          }).then(() => { window.location.href = './';})
         
        })
        .catch((error) => {
          if(error.code == "auth/email-already-in-use"){
            toast.error("Diese E-Mail existiert bereits!")
          }
          if(error.code == "auth/invalid-email"){
            toast.error("Bitte gib eine gültige E-Mail ein!")
          }
        });
  }
};
  //HANDLE MAIL SIGNIN
    
  const handleMailSignIn = () => {
       
    const newErrors = findSignInFormErrors();
    if ( Object.keys(newErrors).length > 0 ) {      
      Object.keys(newErrors).map((key) => {toast.error(newErrors[key])});
        }else{
          signInWithEmailAndPassword(auth, signInForm.email, signInForm.password)
          .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            window.location.href = "./";
          })
          .catch((error) => {
            console.log(error.code)
            if (error.code == 'auth/invalid-credential') {
                toast.error("Ungültige E-Mail oder Passwort!");
            }
            if (error.code == 'auth/invalid-email') {
                toast.error("Gib eine gültige E-Mail ein!");
            } 
            if (error.code == 'auth/missing-email') {
              toast.error("Gib eine E-Mail ein!");
          }   
          if (error.code == 'auth/wrong-password') {
            toast.error("Das von dir eingegebene Passwort ist falsch!");
          } 
          if (error.code == 'auth/user-not-found') {
            toast.error("Dieser Benutzer ist nicht vorhanden!");
          }  
          if (error.code == 'auth/invalid-login-credentials') {
            toast.error("Ungültige E-Mail oder Passwort!");
         }  
          });

          
          
           
    }
  
};
  return (
    <>
      <div>
        <center><h1>Willkommen zurück!</h1></center>
        <br/>
        <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        style={{width: 500}}
        draggable
        pauseOnHover
        theme="light"
        />
      <Form>
      <InputGroup className="mb-3">
        <InputGroup.Text><MdEmail/></InputGroup.Text>
        <Form.Control type='email' className='input-group-no-border' onChange={ e => setSignInField('email', e.target.value) }/>
      </InputGroup>

      <InputGroup className="mb-3">
        <InputGroup.Text><MdPassword/></InputGroup.Text>
        <Form.Control type='password' className='input-group-no-border' onChange={ e => setSignInField('password', e.target.value) }/>
      </InputGroup>
     
      <div className="d-grid gap-2">
      <Button  className='btn-turquoise' onClick={handleMailSignIn}>Anmelden</Button>
      </div>
      
  
      <br/>

      <center>
      <a onClick={() => setShowSignUp(true)} style={{textDecoration: "underline"}}>Passwort vergessen?</a>
      </center>

    </Form>
        
      </div>
     
      <Modal id="modal-1" isOpen={showSignUp} onRequestClose={() => setShowSignUp(false)} style={{padding: 30}}>

  
        <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        style={{width: 500}}
        draggable
        pauseOnHover
        theme="light"
        />
         <center><h1>Willkommen!</h1></center>
        <div className='container-center-align-height' >
      <div>
       <center><h3 className='bold' style={{color: "#393943"}}>{}</h3></center>
        <br/>
      <Form>
    
      <InputGroup className="mb-3">
        <InputGroup.Text><MdSupervisorAccount/></InputGroup.Text>
        <Form.Control placeholder={"Name"} onChange={ e => setSignUpField('username', e.target.value) }/>
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text><MdEmail/></InputGroup.Text>
        <Form.Control placeholder={"E-Mail"} onChange={ e => setSignUpField('email', e.target.value) }/>
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text><MdPassword/></InputGroup.Text>
        <Form.Control type='password' placeholder={"Passwort"} onChange={ e => setSignUpField('password', e.target.value) }/>
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text><MdPassword/></InputGroup.Text>
        <Form.Control type='password' placeholder={"Passwort bestätigen"} onChange={ e => setSignUpField('confirmpassword', e.target.value) }/>
      </InputGroup>
     
      <div className="d-grid gap-2">
      <Button className='btn-turquoise' onClick={handleSignUp}>Registrieren</Button>
      </div>

      

      <br/>


    </Form>
        
      </div>
     
      </div>
       
      </Modal>

    </>
  );
}

export default SignIn;
