
import { initializeApp } from "firebase/app";


const firebaseConfig = {
  apiKey: "AIzaSyCro40_nnXelkLSlUBBbwrdzoIWM2b39Z0",
  authDomain: "filesbug-21926.firebaseapp.com",
  projectId: "filesbug-21926",
  storageBucket: "filesbug-21926.appspot.com",
  messagingSenderId: "458745230311",
  appId: "1:458745230311:web:d4fec7f57506c2a15e0342",
  measurementId: "G-4K8HF6FK1G"
};


const initFirebase = () => {
    const app = initializeApp(firebaseConfig);
}

export default initFirebase;