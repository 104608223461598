import '../assets/css/calendar.css'
import { useState, useEffect } from 'react';
import {React} from 'react';
import ReactDOM from 'react-dom';
import Calendar from 'react-lightweight-calendar';
import Button from 'react-bootstrap/Button';
import {  Modal } from 'react-rainbow-components';
import { If, Then, Else, When, Unless, Switch, Case, Default } from 'react-if';
import moment from 'moment';
import { IoClose } from "react-icons/io5";
import { FaRegClock,FaRegCalendarAlt } from "react-icons/fa";
import { DateTimePicker, CheckboxToggle  } from 'react-rainbow-components';
import { getAuth } from "firebase/auth";
import initFirebase from "../firebase/Init";
import { getFirestore, collection, addDoc, deleteDoc, doc, updateDoc} from "firebase/firestore";
import { ColorRing } from 'react-loader-spinner';


import Circle from '@uiw/react-color-circle';
import Form from 'react-bootstrap/Form';

import getEventList from '../core/EventList';
import getShareList from '../core/ShareList';
import getEventShareList from '../core/EventShareList';
import getUserData from '../core/UserData';

import { ToastContainer, toast } from 'react-toastify';




function FilesCalendar() {
  initFirebase();
  const db = getFirestore();
  const auth = getAuth();
  
  const [pageLoading, setPageLoading] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const [showNew, setShowNew] = useState(false);

  const [shareNew, setShareNew] = useState(false);
  const [shareEdit, setShareEdit] = useState(false);
  const handleCloseNew = () => setShowNew(false);
  const handleShowNew = (data) => {
    setStartNewDate(new Date(data))
    setEndNewDate(new Date(data))
    setNewTitle('')
    setShowNew(true);
  }

  const [eventItemData, setEventItemData] = useState({
    
  });


  const [eventData, setEventData] = useState([]);
  const [sharedEventData, setSharedEventData] = useState([]);
  
  const handleShow = (data) => {
    setEventItemData(data);
    setEditTitle(data.title)
    setShareEdit(data.shared)
    setStartDate(new Date(data.startTime))

    setEndDate(new Date(data.endTime))
    setHex(data.bgColor)
    setShow(true)

  }

  const setEditStartDate = (data) =>{
    if(data.getTime() > endDate.getTime()){
      setStartDate(data);
      setEndDate(data);
    }else{
      setStartDate(data);
    }
    
  }

  const setEditEndDate = (data) =>{
    if(data.getTime() < startDate.getTime()){
      setStartDate(data);
      setEndDate(data);
    }else{
      setEndDate(data);
    }
    
  }

  const setNewStartDate = (data) =>{
    if(data.getTime() > endNewDate.getTime()){
      setStartNewDate(data);
      setEndNewDate(data);
    }else{
      setStartNewDate(data);
    }
    
  }

  const setNewEndDate = (data) =>{
    if(data.getTime() < startNewDate.getTime()){
      setStartNewDate(data);
      setEndNewDate(data);
    }else{
      setEndNewDate(data);
    }
    
  }
  

  const [currentDate, setCurrentDate] = useState(moment.now());

  const [startDate, setStartDate] = useState(new Date());



  const [startNewDate, setStartNewDate] = useState(new Date());
  const [endNewDate, setEndNewDate] = useState(new Date());

  const [endDate, setEndDate] = useState(new Date());
  const [hex, setHex] = useState('#F44336');
  const [newHex, setNewHex] = useState('#F44336');

  const [newTitle, setNewTitle] = useState('');

  const [editTitle, setEditTitle] = useState('');

  const pushEventData =  (result) =>{

      var array = [];
      var i = 0;
      result.forEach((doc) => {
    
        
          array.push({docid: doc.id, uid: doc.data().uid, title: doc.data().event.title, startTime: doc.data().event.startTime, endTime: doc.data().event.endTime, bgColor: doc.data().event.bgColor, textColor: doc.data().event.textColor, id: doc.data().event.id, shared: doc.data().shared});
          i++;
          if(i == result.size){
          setEventData(array);
         
          }
        
      });
  
  }

  const pushSharedEventData =  (result) =>{
 
    var array = [];
    var i = 0;
    result.forEach((doc) => {
  
      getUserData(doc.data().uid).then((result) => {
       
       
        array.push({docid: doc.id, uid: doc.data().uid, title: result.docs[0].data().username+": "+doc.data().event.title, startTime: doc.data().event.startTime, endTime: doc.data().event.endTime, bgColor: doc.data().event.bgColor, textColor: doc.data().event.textColor, id: doc.data().event.id, shared: doc.data().shared});
        i++;
        if(i == result.size){
        setSharedEventData(array);
       
        }
       });
       
      
    });

}

  

  useEffect(() => {
    
  
   
    const getData = async () =>{
      getEventList(auth.currentUser.uid).then((result) => {
       
        pushEventData(result)
       
       });
    }

    const getShare = async () =>{
    
      getShareList(auth.currentUser.uid).then((result) => {
       
        if(result.size > 0)
        {
      console.log(result.size)
          result.forEach((doc) => {
     
            getEventShareList(doc.data().uid).then((results) => {
       
              pushSharedEventData(results)
             
             });
          })
        }
       
       
       });
    }

      
    
     Promise.all([getData(), getShare()]).then(setPageLoading(true));


  }, []); 


  useEffect(() => {
    if(pageLoading){
    const handleReplaceDays = () => {
      const newDays = ['MO', 'DI', 'MI', 'DO', 'FR', 'SA', 'SO'];

     
      const daysNode = ReactDOM.findDOMNode(document.querySelector('.days-component'));

      
      while (daysNode.firstChild) {
        daysNode.removeChild(daysNode.firstChild);
      }

      
      newDays.forEach((newDay) => {
        const newDayElement = document.createElement('div');
        newDayElement.className = 'days-component__day';
        newDayElement.textContent = newDay;
        daysNode.appendChild(newDayElement);
      });
    };

    const handleReplaceText = () => {
      const buttonText = 'Heute';

      
      const buttonNode = ReactDOM.findDOMNode(document.querySelector('.arrow-button'));

      
      if (buttonNode instanceof HTMLButtonElement) {
        buttonNode.textContent = buttonText;
      }
    };

    const changeMonthName = () => {
      const daysNode = ReactDOM.findDOMNode(document.querySelector('.calendar__navigation__month-text'));
      if(daysNode.children[0].textContent.includes("January"))
      {
        daysNode.children[0].textContent = "Januar "+daysNode.children[0].textContent.substr(daysNode.children[0].textContent.length - 4)
      }
      if(daysNode.children[0].textContent.includes("February"))
      {
        daysNode.children[0].textContent = "Feber "+daysNode.children[0].textContent.substr(daysNode.children[0].textContent.length - 4)
      }
      if(daysNode.children[0].textContent.includes("March"))
      {
        daysNode.children[0].textContent = "März "+daysNode.children[0].textContent.substr(daysNode.children[0].textContent.length - 4)
      }
      if(daysNode.children[0].textContent.includes("April"))
      {
        daysNode.children[0].textContent = "April "+daysNode.children[0].textContent.substr(daysNode.children[0].textContent.length - 4)
      }
      if(daysNode.children[0].textContent.includes("May"))
      {
        daysNode.children[0].textContent = "Mai "+daysNode.children[0].textContent.substr(daysNode.children[0].textContent.length - 4)
      }
      if(daysNode.children[0].textContent.includes("June"))
      {
        daysNode.children[0].textContent = "Juni "+daysNode.children[0].textContent.substr(daysNode.children[0].textContent.length - 4)
      }
      if(daysNode.children[0].textContent.includes("July"))
      {
        daysNode.children[0].textContent = "Juli "+daysNode.children[0].textContent.substr(daysNode.children[0].textContent.length - 4)
      }
      if(daysNode.children[0].textContent.includes("August"))
      {
        daysNode.children[0].textContent = "August "+daysNode.children[0].textContent.substr(daysNode.children[0].textContent.length - 4)
      }
      if(daysNode.children[0].textContent.includes("September"))
      {
        daysNode.children[0].textContent = "September "+daysNode.children[0].textContent.substr(daysNode.children[0].textContent.length - 4)
      }
      if(daysNode.children[0].textContent.includes("October"))
      {
        daysNode.children[0].textContent = "Oktober "+daysNode.children[0].textContent.substr(daysNode.children[0].textContent.length - 4)
      }

      if(daysNode.children[0].textContent.includes("November"))
      {
        daysNode.children[0].textContent = "November "+daysNode.children[0].textContent.substr(daysNode.children[0].textContent.length - 4)
      }
      if(daysNode.children[0].textContent.includes("December"))
      {
        daysNode.children[0].textContent = "Dezember "+daysNode.children[0].textContent.substr(daysNode.children[0].textContent.length - 4)
      }
    
    }
    
    ReactDOM.findDOMNode(document.querySelector('.arrow-button')).addEventListener("click", changeMonthName())

    

    handleReplaceText();
    
    handleReplaceDays();

  }
  }); 

  const handleUpdate = (id) => {
    if(editTitle == ''){
      toast.error("Bitte gib einen Titel an!")
    }else{
    
    setPageLoading(false)
    updateDoc(doc(db, "calendar", id),{
      uid: auth.currentUser.uid, 
      shared: shareEdit,
      event: 
      {
        id: eventItemData.id,
        startTime: startDate.toISOString(),
        endTime: endDate.toISOString(),
        title: editTitle,
        bgColor: hex,
        uid: auth.currentUser.uid, 
        textColor: "#fff"
      }
    }).then(() => {
      handleClose();
      clearEventData().then(() => {
        getEventList(auth.currentUser.uid).then((result) => {
     
          pushEventData(result)
         
         });
         setPageLoading(true)
    }) 
  });
    }
  }

  const handleDelete = (id) => {
    setPageLoading(false)
    deleteDoc(doc(db, "calendar", id)).then(() => {
      handleClose();
      clearEventData().then(() => {
        getEventList(auth.currentUser.uid).then((result) => {
     
          pushEventData(result)
         
         });
         setPageLoading(true)
    }) 
  });

  }

  const handleSubmit = () => {
setPageLoading(false)
if(newTitle == ''){
  toast.error("Bitte gib einen Titel an!")
}else{

  addDoc(collection(db, "calendar"), {
    uid: auth.currentUser.uid,
    shared: shareNew,
    event: {startTime: startNewDate.toISOString(), endTime: endNewDate.toISOString(), id: (Date.now() + Math.random()).toString(36).replace('.', ''), title: newTitle, bgColor: newHex, textColor: "#fff"}
  }).then(() => {
    handleCloseNew()
      clearEventData().then(() => {
        getEventList(auth.currentUser.uid).then((result) => {
     
          pushEventData(result)
         
         });
         setPageLoading(true)
    }) 
  });
}
   
  }


  function clearEventData (){
    return new Promise((resolve) => {
      setEventData([]);
      
      resolve(true);
    });

  }

  if(!pageLoading){
    return (
      <main style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
    <ColorRing
  visible={true}
  height="200"
  width="200"
  ariaLabel="blocks-loading"
  wrapperStyle={{}}
  wrapperClass="blocks-wrapper"
  colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
/>
  </main>
    );
  }else{
    return (
    
      <main style={{padding: 30}}>

       <Calendar
        data={[].concat(eventData, sharedEventData)}
        currentView='MONTH'
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        activeTimeDateField='startTime-endTime' // Or just startTime or just endTime
        weekStartsOn={1} // Monday
      
        
        enableHoverEffect={true}
       
        timeDateFormat={{
          day: 'EEEE',
          hour: 'HH:mm',
          monthYear: 'LLLL-yyyy',
        }}
        onDayNumberClick={(day) => {
        
          handleShowNew(day)
        }}
        
        
        onItemClick={(item) => {
        
          handleShow(item)
    
         
        }}
        onCellClick={(value) => {
          handleShowNew(value.timeDateUTC)
        }}
  
        
        renderItemText={(data) => {
          // Custom rendering of event element text
         return <p style={{cursor: "pointer"}}>{data.title}</p>;
       }}
      
        
      />

<Modal id="modal-1" isOpen={show} onRequestClose={handleClose} style={{padding: 30, paddingTop: 60}}> 

      
      
          <If condition={eventItemData.uid == auth.currentUser.uid}>
            <Then>
              <div>
          <Form.Control type="text" placeholder="Titel" style={{textAlign: "center", backgroundColor: hex, color: eventItemData.textColor, }}  value={editTitle} onChange={ e => setEditTitle(e.target.value) } className="placeholder-white"/>
          <br/>
          <DateTimePicker
               
                  cancelLabel="Schließen"
                  borderRadius="semi-square"
                  hour24={true}
                  value={startDate}
                  icon={<FaRegCalendarAlt/>}
                  isCentered={true}
                  minDate={new Date()}
                  onChange={value => { setEditStartDate(value) }}
              /><br/>
              <DateTimePicker
                  cancelLabel="Schließen"
                  borderRadius="semi-square"
                  hour24={true}
                  value={endDate}
                  icon={<FaRegCalendarAlt/>}
                  minDate={new Date()}
                  isCentered={true}
                  onChange={value => { setEditEndDate(value) }}
              /><br/>
                  <Circle
        colors={[ '#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800', '#FF5722', '#795548', '#607D8B' ]}
        color={hex}
        onChange={(color) => {
          setHex(color.hex);
          
        }}
      />
  <center><br/>
  <CheckboxToggle
                  id="checkbox-toggle-component-1"
                  label="Mit Freunden Teilen"
            value={shareEdit}
            onChange={() => {
              if(shareEdit == true){
                setShareEdit(false)
              }else{
                setShareEdit(true)
              }
            }}
            
              />
  </center>
              </div>
          </Then>
            <Else>

            <center>
          <span style={{backgroundColor: eventItemData.bgColor, color: eventItemData.textColor, padding: 10, textAlign: "center", borderRadius: 8, fontSize: 14}}>{eventItemData.title}</span><br/>
         <If condition={moment(eventItemData.startTime).format("DD.MM.YY") == moment(eventItemData.endTime).format("DD.MM.YY")}>
          <Then>
          <br/> <small><FaRegCalendarAlt style={{marginBottom:4}}/> {moment(eventItemData.startTime).format("DD.MM.YY")}</small>
         
          </Then>
          <Else>
          <br/> <small><FaRegCalendarAlt style={{marginBottom:4}}/> {moment(eventItemData.startTime).format("DD.MM.YY")} - {moment(eventItemData.endTime).format("DD.MM.YY")}</small>
         
          </Else>
         </If>
        <br/><br/>
        <If condition={moment(eventItemData.startTime).format("HH:MM") == moment(eventItemData.endTime).format("HH:MM")}>
          <Then>
          <small><FaRegClock style={{marginBottom:4}}/> {moment(eventItemData.startTime).format("HH:MM")} - {moment(eventItemData.endTime).format("HH:MM")}</small>
          
          </Then>
          <Else>
          <small><FaRegClock style={{marginBottom:4}}/> {moment(eventItemData.startTime).format("HH:MM")}</small>
          
          </Else>
        </If>
          </center>
  
            </Else>
          </If>
          
          
         
  
  <If condition={eventItemData.uid == auth.currentUser.uid}>
          <Then>
          
<center>
  <br/>
            <Button className='btn-turquoise' size="sm" onClick={() => {handleUpdate(eventItemData.docid)}} style={{marginRight: 10}}>Bearbeiten</Button>
            <Button variant="danger" size="sm" onClick={() => {handleDelete(eventItemData.docid)}}>Löschen</Button>
            </center>
          </Then>
          </If>
  
        </Modal>
  
  
        <Modal id="modal-1" isOpen={showNew} onRequestClose={handleCloseNew} style={{padding: 30, paddingTop: 60}}> 

        
      
  
          <div>
          <Form.Control type="text" placeholder="Titel" style={{textAlign: "center", backgroundColor: newHex, color: '#fff', }} className="placeholder-white"  onChange={ e => setNewTitle(e.target.value) }/>
          <br/>
          <DateTimePicker
               
                  cancelLabel="Schließen"
                  borderRadius="semi-square"
                  hour24={true}
                  value={startNewDate}
                  icon={<FaRegCalendarAlt/>}
                  isCentered={true}
                  minDate={new Date()}
                  onChange={value => { setNewStartDate(value) }}
              /><br/>
              <DateTimePicker
                  cancelLabel="Schließen"
                  borderRadius="semi-square"
                  hour24={true}
                  value={endNewDate}
                  icon={<FaRegCalendarAlt/>}
                  minDate={new Date()}
                  isCentered={true}
                  onChange={value => { setNewEndDate(value) }}
              /><br/>
                  <Circle
        colors={[ '#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800', '#FF5722', '#795548', '#607D8B' ]}
        color={newHex}
        onChange={(color) => {
          setNewHex(color.hex);
         
        }}
      />
  <center><br/>
  <CheckboxToggle
                  id="checkbox-toggle-component-1"
                  label="Mit Freunden Teilen"
            value={shareNew}
            onChange={() => {
              if(shareNew == true){
                setShareNew(false)
              }else{
                setShareNew(true)
              }
            }}
            
              />
  </center>
  
          </div>
         <br/>
  <center>
            <Button className='btn-turquoise' size="sm" onClick={handleSubmit}>Erstellen</Button>
            </center>
        </Modal>
        <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        style={{width: 500}}
        draggable
        pauseOnHover
        theme="light"
        />
   
        </main>
    
         
       
      );
  }
  
  

 
}

export default FilesCalendar;
