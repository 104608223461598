import initFirebase from "../firebase/Init";

import { getFirestore, collection, query, getDocs, where} from "firebase/firestore";

export default function getTodosShareList (uid) {
   
        initFirebase();
    
        return new Promise((resolve) => {
            const db = getFirestore();
           
              
              const querySnapshot = getDocs(query(collection(db, "todos"), where("uid", "==", uid), where("shared", "==", true)));
              if (querySnapshot) {
                  resolve(querySnapshot)
                } else {
                  resolve(null)
              }
           
            
        });
}