import Logo from '../assets/images/logo.svg';
import Hero from '../assets/images/landing/hero.svg'
import Background from '../assets/images/landing/background.svg'
import { useState, useEffect } from 'react';
import '../assets/css/landing.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Button, Image} from 'react-bootstrap';
import { motion } from "framer-motion"
import SignIn from '../components/landing/Signin';
import SignUp from '../components/landing/Signup';
import { Card, Modal } from 'react-rainbow-components';

function Landing() {

  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);

  const updateWindowDimensions = () => {
     
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
  };

  

  useEffect(() => {
      window.addEventListener('resize', updateWindowDimensions);
  
  }, []);

  const [showSignIn, setShowSignIn] = useState(false);

  const handleCloseSignIn = () => setShowSignIn(false);
  const handleShowSignIn = () => setShowSignIn(true);

  const [showSignUp, setShowSignUp] = useState(false);

  const handleCloseSignUp = () => setShowSignUp(false);
  const handleShowSignUp = () => setShowSignUp(true);
  
  return (
    <>
     <div style ={{padding: 30}}>
    <Navbar expand="lg" className='nav-landing' fixed="top" >
      <Container fluid>
        <Navbar.Brand href="#home"><img src={Logo} width={35}/></Navbar.Brand>
       
          <Nav className="justify-content-end">

          <Button className='btn-turquoise' onClick={() => {handleShowSignIn()}}>Anmelden</Button>
          </Nav>
      
      </Container>
    </Navbar>
    </div>
    <Container fluid style={{display: "flex", justifyContent: "center", alignItems: "center",  height: height-60, padding: 0, margin: 0, backgroundImage: `url(${Background})`}} className='landing-background'>
     
   
    
    
     <div style={{maxWidth: 700}}>
 <center>
      <motion.div   initial={{ opacity: 0, translateY: 550 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{
            type: 'spring',
            stiffness: 150,
            damping: 30,
            delay: 0.2,
          }}><h1><b> Deine Termine neu erleben!</b> </h1><Image src={Hero} rounded style={{ width: width/4, maxWidth: 200, minWidth: 300}}/>
      </motion.div>
  

      <motion.div   initial={{ opacity: 0, translateY: 550 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{
            type: 'spring',
            stiffness: 150,
            damping: 30,
            delay: 0.3,
          }}><p style={{maxWidth: 500}}className='text-muted'> Nie war es einfacher, den Überblick über deine Termine zu behalten und gleichzeitig die Planung mit anderen zu vereinfachen.</p>
      <br/><SignUp/>
      </motion.div>
      </center>
</div>

    </Container>
  
    <Modal id="modal-1" isOpen={showSignIn} onRequestClose={handleCloseSignIn} style={{padding: 30}}>

  
    
        <SignIn/>
      </Modal>
     
  
    </>
  );
}

export default Landing;
