

import Invite from '../components/Invite';
import { params } from 'react-easy-params';
import { useEffect, useState } from 'react';
import getShareList from '../core/ShareList';

import { getFirestore, collection, addDoc, deleteDoc, doc, updateDoc} from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import initFirebase from '../firebase/Init';
import getUserData from '../core/UserData';
import getFriendList from '../core/FriendList';
import '../assets/css/profile.css'

import { Col, Row, Container, Button, Form, Image, Dropdown} from 'react-bootstrap';
import { Card } from "react-rainbow-components";
import { BiTrash, BiPlus } from 'react-icons/bi';
import { ToastContainer, toast } from 'react-toastify';
import getShareDocument from '../core/ShareDocument';
import getInviteList from '../core/InviteList';
import { DateTimePicker, CheckboxToggle  } from 'react-rainbow-components';



function FilesProfile() {
    initFirebase();
    const db = getFirestore();
    const auth = getAuth();
    const [userName, setUserName] = useState('');
    const [sms, setSMS] = useState(false);
    const [docID, setDocID] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [friendList, setFriendlist] = useState([])
    const [pageLoading, setPageLoading] = useState(false);
    useEffect(() => {  
        /*
        const accountSid = 'AC809c8efb5e030da623561ca78973a7dd';
const authToken = '678ba80c5e73fa5df5baf7d0c0a0e26e';
const client = require('twilio')(accountSid, authToken);

client.messages
    .create({
        body: 'Your appointment is coming up on July 21 at 3PM',
        from: 'whatsapp:+14155238886',
        to: 'whatsapp:+436601771586'
    })
    .then(message => console.log(message.sid))
    .done();
        */
const getFriends = async () =>{
    getFriendList(auth.currentUser.uid).then((result) => {
        pushFriendList(result)
    
    });
}

        
const getUserInfo = async () =>{
    getUserData(auth.currentUser.uid).then((result) => {
        setUserName(result.docs[0].data().username)
        setUserEmail(result.docs[0].data().email)
        setSMS(result.docs[0].data().sms)
        setDocID(result.docs[0].id)
    });
}

       
           
        
        
        if(params.invite != undefined){

            
            invite(params.invite)

        }
        Promise.all([getFriends(), getUserInfo()]).then(setPageLoading(true));


     },[]);

     const invite = (id) => {
        getUserData(id).then((dat) => {
            if(dat.size > 0){
                if(dat.docs[0].data().uid != auth.currentUser.uid)
                {
                    getInviteList(id, auth.currentUser.uid).then((res) => {
                        console.log(res.size)
                        if(res.size < 1){
                           
                        addDoc(collection(db, "shared"), {
                        uid: auth.currentUser.uid,
                        share: id
                      }).then(() =>
                        {
                            addDoc(collection(db, "shared"), {
                                uid: id,
                                share: auth.currentUser.uid
                              }).then(() =>
                                {
                                    toast.success("Ihr habt euch nun Verbunden!")
                                }
                              );
                        }
                      );
                    }else{
                        
                        toast.error("Ihr seid bereits Verbunden!")
                    }
                });
                

           
            }else{
                toast.error("Du kannst dich nicht selber Einladen!")
            }
            }else{
                toast.error("Benutzer wurde nicht gefunden!")
            }
        });
     }

     const pushFriendList = (data) => {
        
        var array = [];
        var i = 0;
        data.forEach((doc) => {
    
          getUserData(doc.data().uid).then((result) => {
        
           
            array.push({id: doc.id, state: doc.data().state, uid: result.docs[0].data().uid, username: result.docs[0].data().username, email:  result.docs[0].data().email});
            i++;
            if(i == data.size){
                setFriendlist(array);
             
               }
           });
           
         
           
           
            
            
          
        });
     }

     const handleSharedClick = (index, state) => {

        setFriendlist(prevListe => {
          const neueListe = [...prevListe];
          neueListe[index].state = state;
          return neueListe;
        });
      };

     

if(!pageLoading){
return(
<main></main>
)
}else{
  return (
    
    <>
     
 
      <main style={{padding: 30}}>
       

         
         <Container>
            <center>
         <Image src="https://www.mtv-in-la.de/wp-content/themes/yootheme/cache/portrait_dummy_m_teamseite-ea0a7479.jpeg" roundedCircle  width={100}  className='profile-image'/>
         <h4>{userName}</h4>
                    <small className='text-muted'>{userEmail}</small>
                    <br/>
                    <br/>
                    SMS-Benachrichtigung<br/> <CheckboxToggle style={{position: "relative", top: 12}} value={sms} onChange={() => {
              if(sms == true){
             
               setSMS(false)
                
               
              }else{
                setSMS(true)
              }
            }}/>
     <br/><br/> <br/><br/>
        <Card style={{padding: 30}}>
        <Dropdown>
      <Dropdown.Toggle className='btn-gray' size='sm' style={{position: "absolute", top: -20, right: 75}}>
        <BiPlus/>
      </Dropdown.Toggle>

      <Dropdown.Menu>
      <Form.Control size="sm" type="text" placeholder="ID" style={{border: "none", textAlign: "center"}} onKeyDown={event => {
              if (event.key === "Enter") {
                invite(event.target.value)
              }
            }} />
       
      </Dropdown.Menu>
    </Dropdown>
    
        <h4 style={{marginTop: 40}}>Freunde</h4>  
            <div>
     
            <Invite/></div>
         
        
         
            <Container>
           
            {friendList.map((item, i) => {
           
                
             return(
             <Row className='row-profile'  key={i} >
             <Col sm  style={{textAlign: "center"}}><Image src="https://www.mtv-in-la.de/wp-content/themes/yootheme/cache/portrait_dummy_m_teamseite-ea0a7479.jpeg" roundedCircle className='profile-image'  width={50} />
                 </Col>
             <Col sm xs={12}><center>{item.username}<br/><small className='text-muted'>{item.email}</small></center></Col>
             <Col sm style={{textAlign: "center"}}><CheckboxToggle style={{position: "relative", top: 12}} value={item.state} onChange={() => {
              if(item.state == true){
             
                getShareDocument(auth.currentUser.uid, item.uid).then((result) => {
                    if(result.size > 0){
                        updateDoc(doc(db, "shared", result.docs[0].id),{
                            state: false
                          }).then(() => {
                            handleSharedClick(i, false)
                        
                        });
                    }
                });
                
               
              }else{
                getShareDocument(auth.currentUser.uid, item.uid).then((result) => {
                    if(result.size > 0){
                        updateDoc(doc(db, "shared", result.docs[0].id),{
                            state: true
                          }).then(() => {
                            handleSharedClick(i, true)
                        
                        });
                    }
                });
                
              }
            }}/></Col>
           </Row>)
            })}

            

    </Container>
           
     
    </Card>
       
      </center>
    </Container>
        
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        style={{width: 500}}
        draggable
        pauseOnHover
        theme="light"
        />
         
      </main>
   
  
  </>
  
       
     
    );
}

 
}

export default FilesProfile;
