import React from 'react';
import ReactDOM from 'react-dom/client';
//CSS
import './index.css';
//PAGES
import Home from './pages/Home'
import Landing from './pages/Landing';
import Calendar from './pages/Calendar';
import Profile from './pages/Profile';
//STUFF
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import initAuth from './firebase/Auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
initAuth().then((result) => {
  if(result){
    root.render(
 
      <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/calendar" element={<Calendar />}></Route>
      <Route path="/profile" element={<Profile />}></Route>
      </Routes>
    </BrowserRouter>,
    
    );
    
  }else{
    root.render(
 
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />}></Route>
        
      </Routes>
    </BrowserRouter>,
    
    );
    
  }
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
