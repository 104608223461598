import initFirebase from "../firebase/Init";

import { getFirestore, collection, query, getDocs, where} from "firebase/firestore";

export default function getFriendList (uid) {
   
        initFirebase();
    
        return new Promise((resolve) => {
            const db = getFirestore();
           
              
              const querySnapshot = getDocs(query(collection(db, "shared"), where("share", "==", uid)));
              if (querySnapshot) {
                  resolve(querySnapshot)
                } else {
                  resolve(null)
              }
           
            
        });
}