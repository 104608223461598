import initFirebase from "./Init";
import { getAuth , onAuthStateChanged} from "firebase/auth";



export default function initAuth () {
        initFirebase();
        const auth = getAuth();
        return new Promise((resolve) => {
            onAuthStateChanged(auth, (user) => {
              if (user) {
                resolve(user)
              } else {
                resolve(null)
              }
            });
        });
}
