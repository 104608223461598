import QRCode from "react-qr-code";
import { getAuth } from "firebase/auth";
import Logo from "../assets/images/logo.svg"
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Modal } from "react-rainbow-components";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { BiClipboard } from "react-icons/bi";
import { InputGroup, Form } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';



function Invite() {
  
    const auth = getAuth();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  

  return (
    
    <>
    <Button className="btn-turquoise" onClick={handleShow} size="sm" style={{position: "absolute", right: 10, top: 10}}>
       Einladen
      </Button>

      <Modal id="modal-1" isOpen={show} onRequestClose={handleClose} style={{padding: 30}}>
     
        
        
            <center>
        <div style={{maxWidth: 400}}><center>
            <h4>Verbinde dich mit deinen Freunden</h4>
            <QRCode value={"https://filesbug.net/profile?invite="+auth.currentUser.uid}  style={{width: 200}} />

        <InputGroup >
        <Form.Control
          value={auth.currentUser.uid}
        />
        <InputGroup.Text id="basic-addon1">
            <CopyToClipboard text={"https://filesbug.net/profile?invite="+auth.currentUser.uid} onCopy={() => {toast.success("Code in deine Zwischenablage Kopiert!")}}>
                <BiClipboard style={{cursor: "pointer"}}/>
            </CopyToClipboard></InputGroup.Text>
         </InputGroup>
         <br/>
       
        </center></div>
        </center>
        <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        style={{width: 500}}
        draggable
        pauseOnHover
        theme="light"
        />
   
       
      </Modal>
      
    </>
  
       
     
    );


 
}

export default Invite;
